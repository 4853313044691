import { VNode } from 'preact';
import { ClassProp } from '../util';


export const Logo = ({ className }: ClassProp): VNode => (
	<svg
		className={ className }
		enable-background="new -127.256 -48.455 384 146.213"
		viewBox="-127.256 -48.455 384 146.213"
		xmlns="http://www.w3.org/2000/svg"
	>
		<g fill="#d40072"><path d="m163.031-12.285-31.341-18.084-31.334-18.086s-26.903 4.387-65.787 16.037c-38.88 11.647-89.739 30.553-137.655 59.589 0 0 45.569-14.611 101.619-26.851 56.047-12.238 122.576-22.102 164.498-12.605z"/><path d="m-127.256 40.81s52.762-17.592 127.142-30.998c74.382-13.406 170.382-22.629 256.858-5.89 0 0-3.901 14.443-15.895 33.391-11.99 18.953-32.078 42.411-64.434 60.445 0 0 15.202-15.84 17.818-31.988 2.609-16.148-7.364-32.605-57.72-33.806-59.546-3.27-125.49-1.062-176.543 1.972-51.058 3.027-87.226 6.874-87.226 6.874z"/></g>
	</svg>
);
