import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import { VNode } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Form, Checkbox } from '../../components';
import { LoginStatus } from './login-route';


export type LoginFormSubmitHandler = (username: string, password: string, remember: boolean) => void;
type LoginFormComponent = (props: {
	onSubmit: LoginFormSubmitHandler;
	status: LoginStatus;
}) => VNode;
export const LoginForm:LoginFormComponent = ({ onSubmit, status }) => {
	const [ username, setUsername ] = useState('');
	const [ password, setPassword ] = useState('');
	const [ remember, setRemember ] = useState(true);

	const usernameInputEl = useRef<HTMLInputElement>();

	const focusUsernameInput = () => {
		setTimeout(() => {
			usernameInputEl.current?.focus();
		});
	};

	useEffect(focusUsernameInput, []);
		
	const handleSubmit = useCallback(() => {
		onSubmit(username, password, remember);
	}, [ onSubmit, username, password, remember ]);
	
	const disabled = status === 'loading';

	useEffect(() => {
		focusUsernameInput();
	}, [ status ]);
	
	return (
		<Form
			submitLabel="SIGN IN"
			disabled={ disabled }
			onSubmit={ handleSubmit }
		>
			<TextField
				inputRef={ usernameInputEl }
				type="email"
				variant="outlined"
				label="Email Address"
				required
				value={ username }
				onChange={ ev => setUsername(ev.target.value) }
				autoFocus
				disabled={ disabled }
			/>
			<TextField
				type="password"
				variant="outlined"
				label="Password"
				required
				value={ password }
				onChange={ ev => setPassword(ev.target.value) }
				disabled={ disabled }
			/>

			<FormControlLabel
				label="Remember me on this device"
				control={ (
					<Checkbox
						checked={ remember }
						onChange={ (ev, value) => setRemember(value) }
						disabled={ disabled }
					/>
				) }
			/>
		</Form>
	);
};
