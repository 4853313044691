import { api } from '../api';
import { log } from '.';


export const loginWaitAndRedirect = async (username: string, password: string, remember: boolean, onError: () => void = () => {/**/}): Promise<void> => {
	const response = await api.login.POST({ username, password, remember });
	if(response.status === 200) {
		setTimeout(() => {
			window.location.href = response.body.callback;
		}, 3000);
		
		return;
	}

	log.error('LOGIN_FAILED', response);
	onError();
};
