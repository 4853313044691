import Typography from '@material-ui/core/Typography/Typography';
import { VNode } from 'preact';
import { Alert, UnknownErrorAlert } from '../../components';
import { LoginStatus } from './login-route';


type LoginFormErrorComponent = (props: {
	status: LoginStatus
}) => VNode | null;

export const LoginFormErrors: LoginFormErrorComponent = ({ status }) => {
	switch(status) {
		case 'error':
			return <UnknownErrorAlert action="while logging in"/>;
		case 'unauthorized':
			return (
				<Alert
					severity="error"
					title="Invalid credentials"
					content={ (
						<Typography>
							Please check that your username and password are correct, then try again.
						</Typography>
					) }
				/>
			);
		default:
			return null;
	}
};
