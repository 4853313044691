import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import { VNode } from 'preact';
import { Logo } from './logo';


const useStyles = makeStyles(theme => ({
	header: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		marginBlockEnd: theme.spacing(4),
	},
	logo: {
		width: theme.spacing(15),
		height: 'auto',
		padding: theme.spacing(2),
	},
}));

type HeaderComponent = (props: { title: string }) => VNode;
export const Header: HeaderComponent = ({ title }) => {
	const styles = useStyles();
	
	return (
		<header className={ styles.header }>
			<Logo className={ styles.logo }/>
			<Typography variant="h5" component="h1">
				{ title }
			</Typography>
		</header>
	);
};
