import { route } from 'preact-router';


export const getCallbackUrl = (): string | null => (
	new URLSearchParams(window.location.search)
		.get('callback')
);


export const makeHrefWithCallbackUrl = (path: string, searchParams: Record<string, string> = {}): string => {
	const callback = getCallbackUrl();
	if(callback) {
		searchParams.callback = callback;
	}

	if(Object.keys(searchParams).length === 0) {
		return path;
	}

	const params = new URLSearchParams(searchParams);
	return `${path}?${params.toString()}`;
};

export const routeWithCallbackUrl = (path: string, searchParams: Record<string, string> = {}): void => {
	route(makeHrefWithCallbackUrl(path, searchParams));
};
