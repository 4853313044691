import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { VNode } from 'preact';
import { useMemo } from 'preact/hooks';
import cn from 'classnames';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ClassProp } from '../util';


const useStyles = makeStyles(theme => ({
	alert: {
		padding: theme.spacing(2),

		display: 'grid',
		gridTemplateAreas: `
			'icon title'
			'icon content'
		`,
		gap: theme.spacing(1),

		border: '2px solid',
		marginBlockEnd: theme.spacing(2),
	},
	alertWarning: {
		borderColor: theme.palette.warning.main,
		'&, & > *': {
			color: theme.palette.warning.contrastText,
		},
		'& svg': {
			color: theme.palette.warning.main,
		},
	},
	alertError: {
		borderColor: theme.palette.error.main,
		'&, & > *': {
			color: theme.palette.error.main,
		},
	},
	alertSuccess: {
		backgroundColor: `${theme.palette.success.main} !important`,
		borderColor: theme.palette.success.main,
		'&, & > *': {
			color: theme.palette.success.contrastText,
		},
	},

	alertTitle: {
		gridArea: 'title',
		fontWeight: 'bold',
	},
	alertIcon: {
		gridArea: 'icon',
	},
	alertContent: {
		gridArea: 'content',
		display: 'flex',
		flexDirection: 'column',
		'& > p + p': {
			marginTop: theme.spacing(1),
		},
	},
}));

type AlertComponent = (props: ClassProp & {
	title: string;
	severity: 'success' | 'warning' | 'error';
	content?: VNode;
}) => VNode;
export const Alert: AlertComponent = ({ className, title, severity, content }): VNode => {
	const styles = useStyles();

	const icon = useMemo(() => {
		switch(severity) {
			case 'success': return <CheckCircleIcon/>;
			case 'error':   return <ErrorIcon/>;
			case 'warning': return <WarningIcon/>;
		}
	}, [ severity ]);

	const computedClassName = useMemo(() => (
		cn(className, styles.alert, {
			[styles.alertWarning]: severity === 'warning',
			[styles.alertError]:   severity === 'error',
			[styles.alertSuccess]: severity === 'success',
		})
	), [ severity, styles, className ]);
	
	return (
		<Paper
			className={ computedClassName }
			role="alert"
			elevation={ 0 }
		>
			<div className={ styles.alertIcon }>
				{ icon }
			</div>

			<Typography className={ styles.alertTitle } component="span">
				<strong>{ title }</strong>
			</Typography>
			<div className={ styles.alertContent }>
				{ content ?? null }
			</div>
		</Paper>
	);
};

type UnknownErrorAlertComponent = (props: {
	action: string;
}) => VNode;
export const UnknownErrorAlert: UnknownErrorAlertComponent = ({ action }) => (
	<Alert
		severity="error"
		title={ `An error occurred ${action}.` }
		content={ (
			<Typography>
				Please try again in a moment.<br/>
				If the problem persists, please contact our support.
			</Typography>
		) }
	/>
);

export const SuccessfulLoginAlert = (): VNode => (
	<>
		<Alert
		severity="success"
		title="Your password has been updated."
		content={ (
			<Typography component="p">
				You are now being logged in...
			</Typography>
		) }
		/>
		<Box margin="auto" marginTop={ 2 }>
			<CircularProgress/>
		</Box>
	</>
);
