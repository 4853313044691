import Paper from '@material-ui/core/Paper/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography/Typography';
import { FunctionComponent } from 'preact';
import { FeaturedYacht } from './featured-yacht';


const useStyles = makeStyles(theme => ({
	page: {
		display: 'grid',
		gridTemplateAreas: "'featured' 'content'",
		gridTemplateRows: '40vh auto',

		[theme.breakpoints.up('md')]: {
			gridTemplateAreas: "'featured content'",
			gridTemplateColumns: '1fr minmax(350px, 20vw)',
			gridTemplateRows: '1fr',
		},

		width: '100vw',
		minHeight: '100vh',
	},
	pageFeatured: {
		gridArea: 'featured',
	},
	pageContent: {
		gridArea: 'content',
		display: 'grid',

		gridTemplateAreas: "'content' 'copyright'",
		gridTemplateRows: '1fr auto',
		padding: theme.spacing(2, 1),

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2, 2),
		},
	},
	pageInnerContent: {
		display: 'flex',
		flexDirection: 'column',
		'& > * + *': {
			marginTop: theme.spacing(1),
		},
	},
}));

export const Page: FunctionComponent = ({ children }) => {
	const styles = useStyles();
	
	return (
		<div className={ styles.page }>
			<FeaturedYacht className={ styles.pageFeatured }/>
			<Paper
				className={ styles.pageContent }
				square
				elevation={ 10 }
				component="main"
			>
				<div className={ styles.pageInnerContent }>
					{ children }
				</div>
				<Typography
					variant="body2"
					color="textSecondary"
					align="center"
				>
					Copyright &#169; Charter Index
				</Typography>
			</Paper>
		</div>
	);
};
