import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FunctionComponent } from 'preact';
import { useCallback } from 'preact/hooks';


const useStyles = makeStyles(theme => ({
	form: {
		display: 'grid',
		gridAutoFlow: 'columns',
		gap: theme.spacing(1),
		width: '100%',
	},
}));

type FormComponent = FunctionComponent<{
	submitLabel: string;
	disabled?: boolean;
	onSubmit: () => void;
}>;
export const Form: FormComponent = ({ submitLabel, disabled, onSubmit, children }) => {
	const styles = useStyles();

	const handleSubmit = useCallback((ev: Event) => {
		ev.preventDefault();
		if(!disabled) {
			onSubmit();
		}
	}, [ disabled, onSubmit ]);
	
	return (
		<form
			className={ styles.form }
			onSubmit={ handleSubmit }
		>
			{ children }
			<Button
				type="submit"
				variant="contained"
				color="secondary"
				size="large"
				disabled={ disabled }
			>
				{ submitLabel }
			</Button>
		</form>
	);
};
