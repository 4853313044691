import { AUTHENTICATION_HEADER_NAME, setCookiesFromCiAuthHeader } from '@charterindex/gangplank';
import { getCallbackUrl } from './callback';
import { log } from './util';


declare const process: {
	env: Record<string, string>;
};

// eslint-disable-next-line prefer-destructuring
const GANGPLANK_API_URL = process.env.GANGPLANK_API_URL;
if(!GANGPLANK_API_URL) {
	throw new Error('Missing GANGPLANK_API_URL');
}


type ApiResponse<StatusCode extends number, Body extends Record<string, unknown> | undefined = undefined> = {
	status: StatusCode;
	headers: Response['headers'];
	body: Body;
};
namespace ApiResponse {
	export type BadRequest = ApiResponse<400, { errors: string [] }>;
	export type Error = ApiResponse<500>;
}


const fetchApi = async <Response extends ApiResponse<StatusCode, Body>, StatusCode extends number = Response['status'], Body extends Record<string, unknown> = Response['body']>(
	method: 'POST' | 'DELETE',
	path: string,
	body: Record<string, unknown>,
): Promise<Response | ApiResponse.Error> => {
	try {
		const response = await fetch(`${GANGPLANK_API_URL}/${path}`, {
			method,
			headers: {
				'content-type': 'application/json',
			},
			body: JSON.stringify(body),
		});

		if(response.status === 500) {
			throw new Error(`500 ${response.statusText}: ${await response.text()}`);
		}
		
		return {
			status: response.status as StatusCode,
			body: await response.json() as Body,
			headers: response.headers,
		} as Response;
	} catch(error) {
		log.error('Error fetching from gangplank-api', error);
		return { status: 500, body: undefined, headers: new Headers() } as ApiResponse.Error;
	}
};

const handleAuthenticationHeader = (headers: Headers): boolean => {
	if(!headers.has(AUTHENTICATION_HEADER_NAME)) {
		log.error(`Missing ${AUTHENTICATION_HEADER_NAME}`);
		return false;
	}
	
	setCookiesFromCiAuthHeader(headers.get(AUTHENTICATION_HEADER_NAME));
	return true;
};

const extendBodyWithCallbackUrl = (body: { callback?: string }) => {
	if(!body.callback) {
		body.callback = getCallbackUrl() ?? undefined;
	}
};

export namespace api {
	export namespace login {
		export type PostRequest = {
			username: string;
			password: string;
			remember: boolean;
			callback?: string;
		};
		export type PostResponse_NewUser = ApiResponse<403, { reason: 'temp-password', accessToken: string }>;
		export type PostResponse = (
			| ApiResponse<200, { callback: string; }>
			| ApiResponse.BadRequest
			| ApiResponse<401>
			| PostResponse_NewUser
			| ApiResponse<403, { reason: 'password-reset-required' | 'show-disclaimer' }>
		);
		export const POST = async (body: api.login.PostRequest): Promise<api.login.PostResponse | ApiResponse.Error> => {
			extendBodyWithCallbackUrl(body);
			
			const response = await fetchApi<api.login.PostResponse>('POST', 'login', body);
			if(response.status === 200) {
				if(!handleAuthenticationHeader(response.headers)) {
					return {
						status: 500,
						headers: response.headers,
						body: undefined,
					};
				}
			}

			return response;
		};
	
		export type DeleteRequest = {
			idToken: string;
			refreshToken: string;
			callback?: string;
		};
		export type DeleteResponse = (
			| ApiResponse<200, { callback: string }>
			| ApiResponse<400, { errors: string[]  }>
		);
		export const DELETE = async (body: api.login.DeleteRequest): Promise<api.login.DeleteResponse | ApiResponse.Error> => {
			extendBodyWithCallbackUrl(body);

			const response = await fetchApi<api.login.DeleteResponse>('DELETE', 'login', body);

			if(response.status === 200) {
				if(!handleAuthenticationHeader(response.headers)) {
					return {
						status: 500,
						headers: response.headers,
						body: undefined,
					};
				}
			}

			return response;
		};
	}

	export namespace loginNewUser {
		export type Request = {
			accessToken: string;
			username: string;
			oldPassword: string;
			newPassword: string;
			acceptDisclaimer: boolean;
		};

		export type Response = ApiResponse<200>;

		export const POST = (body: api.loginNewUser.Request): Promise<api.loginNewUser.Response | ApiResponse.Error> => (
			fetchApi<api.loginNewUser.Response>('POST', 'login-new-user', body)
		);
	}

	export namespace resetPasswordInit {
		export type Request = {
			username: string;
		};
		export type Response = (
			| ApiResponse<200>
			| ApiResponse.BadRequest
			| ApiResponse<429>
		);
		export const POST = (body: api.resetPasswordInit.Request): Promise<api.resetPasswordInit.Response | ApiResponse.Error> => (
			fetchApi<api.resetPasswordInit.Response>('POST', 'reset-password-init', body)
		);
	}

	export namespace resetPasswordConfirm {
		export const INVALID_PASSWORD = 'invalid-password';
		export const EXPIRED_CODE = 'expired-code';
		export const INVALID_CODE = 'invalid-code';
		
		export type Request = {
			username: string;
			password: string;
			code: string;
		};
		export type Response = (
			| ApiResponse<200>
			| ApiResponse.BadRequest
			| ApiResponse<429>
		);

		export const POST = (body: api.resetPasswordConfirm.Request): Promise<api.resetPasswordConfirm.Response | ApiResponse.Error> => (
			fetchApi<api.resetPasswordConfirm.Response>('POST', 'reset-password-confirm', body)
		);
	}
}
