import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { VNode } from 'preact';
import { useCallback, useEffect, useRef, useState } from 'preact/hooks';
import { Alert } from '../../components';
import { Form } from '../../components/form';
import { PasswordInputs } from '../../components/password-inputs';
import { ResetStatus } from './reset-route';


export type ResetConfirmFormSubmitHandler = (code: string, password: string) => void;

type ResetConfirmFormComponent = (props: {
	status: ResetStatus;
	username: string;
	onSubmit: ResetConfirmFormSubmitHandler;
	isUpgrade: boolean;
}) => VNode | null;
export const ResetConfirmForm: ResetConfirmFormComponent = ({ status, username, onSubmit, isUpgrade }) => {
	const [ code, setCode ] = useState('');
	const codeInputEl = useRef<HTMLInputElement>();
	
	const [ password, setPassword ] = useState('');
	const [ passwordValid, setPasswordValid ]	 = useState(false);

	const canSubmit = status !== 'loading' && code.length && passwordValid;

	const handleSubmit = useCallback(() => {
		onSubmit(code, password);
	}, [ code, password, password ]);
	
	useEffect(() => {
		setTimeout(() => codeInputEl.current?.focus());
	}, []);

	const disabled = status === 'loading';

	return (
		<>
			{ isUpgrade ? (
				<Alert
					severity="warning"
					title="A stronger password is required"
					content={ (
						<>
							<Typography component="p">
								In order to keep your account secure, we have changed our requirements
								for password strength.
							</Typography>
							<Typography component="p">
								Unfortunately, your current password does not meet the new criteria,
								and will need to be changed.
							</Typography>
						</>
					) }
				/>
			) : (
				<Typography component="p">
					Thank you.
				</Typography>
			)}


			<Typography component="p">
				We have sent an email to <strong>{ username }</strong> containing
				a verification code.
			</Typography>
	
			<Typography component="p">
				Once you have received your code, enter it below, along with your new password.
			</Typography>
			<br/><br/>

			<Form
				submitLabel="RESET PASSWORD"
				onSubmit={ handleSubmit }
				disabled={ !canSubmit }
			>
				<TextField
					inputRef={ codeInputEl }
					type="text"
					variant="outlined"
					label="Verification Code"
					required
					value={ code }
					onChange={ ev => setCode(ev.target.value) }
					autoFocus
					disabled={ disabled }
					error={ passwordValid }
				/>

				<PasswordInputs
					password={ password }
					setPassword={ setPassword }
					setValid={ setPasswordValid }
					disabled={ disabled }
				/>
			</Form>
		</>
	);
};
