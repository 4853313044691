
type Tokens = {
	idToken: string;
	accessToken: string;
	refreshToken: string;
};

export const getTokensFromCookies = (): Tokens | null => {
	const { idToken, accessToken, refreshToken } = document.cookie.split(/; ?/)
		.map(cookie => cookie.split('='))
		.reduce((tokens, [ key, value ]) => {
			switch(key) {
				case 'ci_id': return {
					...tokens,
					idToken: value,
				};
				case 'ci_access': return {
					...tokens,
					accessToken: value,
				};
				case 'ci_refresh': return {
					...tokens,
					refreshToken: value,
				};
				default: return tokens;
			}
		}, {} as Partial<Tokens>);

		if(idToken && accessToken && refreshToken) {
			return { idToken, accessToken, refreshToken };
		}
		
		return null;
};
