import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import { RefObject, VNode } from 'preact';
import { useEffect, useMemo, useRef, useState } from 'preact/hooks';
import BulletIcon from '@material-ui/icons/FiberManualRecord';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';


export type InputRequirementsDef = Record<string, (value: string) => boolean>;
type InputRequirementStatus = 'info' | 'ok' | 'error';
type InputRequirementsState = Record<string, InputRequirementStatus>;
type InputRequirementsHook = {
	ref: RefObject<HTMLInputElement>;
	state: InputRequirementsState;
	valid: boolean;
	error: boolean;
};
export const useInputRequirements = (requirements: InputRequirementsDef): InputRequirementsHook => {
	const ref = useRef<HTMLInputElement>();

	const [ value, setValue ] = useState('');
	const [ touched, setTouched ] = useState(false);
	const invalidStatus = touched ? 'error' : 'info';

	const state = useMemo<InputRequirementsState>(() => {
		return Object.entries(requirements)
			.reduce((state, [ label, test ]) => ({
				...state,
				[label]: test(value) ? 'ok' : invalidStatus,
			}), {});
		}, [ requirements, value, invalidStatus ]);
	
	const valid = useMemo(() => (
		Object.values(state).every(status => status === 'ok')
	), [state]);
	
	// Update validity when base on interaction with the <input>.
	useEffect(() => {
		if(!ref.current) {
			throw new Error('No <input> provided.');
		}
		const input = ref.current;

		const updateValueState = () => {
			setValue(input.value);
		};
		updateValueState();

		const handlers: Partial<Record<keyof HTMLElementEventMap, () => void>> = {
			'input': updateValueState,
			'blur': () => { setTouched(true); },
		};

		Object.entries(handlers)
			.forEach(([ event, handler ]) => {
				input.addEventListener(event, handler);
			});

		return () => {
			Object.entries(handlers)
				.forEach(([ event, handler ]) => {
					input.removeEventListener(event, handler);
				});
		};
	});

	return useMemo<InputRequirementsHook>(() => ({
		ref: ref as RefObject<HTMLInputElement>,
		state,
		valid,
		error : touched && !valid,
	}), [state]);
};

const useStyles = makeStyles(theme => ({
	inputRequirements: {
		listStyle: 'none',
		marginTop: theme.spacing(1),
		padding: theme.spacing(0, 1),
	},
	requirement: {
		display: 'grid',
		gridTemplateColumns: '24px 1fr',

		'& > svg': {
			margin: 'auto',
			width: 18,
			height: 18,
			marginBottom: 2,
		},
		'& > span': {
			margin: 'auto 0',
		},

		'&.info': {
			'& svg': {
				width: 6,
				height: 6,
			},
		},
		'&.error': {
			'& svg': {
				color: theme.palette.error.main,
			},
		},
		'&.ok': {
			'& svg': {
				color: theme.palette.success.main,
			},
		},
	},

	requirementIcon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		'& > svg': {
			margin: 'auto',
			width: 18,
			height: 18,
			marginBottom: 2,
		},
	},

	requirementLabel: {
		display: 'flex',
		alignItems: 'center',
	},
}));

const InputRequirementStatusIcon = ({ status }: { status: InputRequirementStatus }): VNode => {
	switch(status) {
		case 'info':
			return <BulletIcon/>;
		case 'error':
			return <ErrorIcon/>;
		case 'ok':
			return <CheckCircleIcon/>;

	}
};

type InputRequirementsComponent = (props: { state: InputRequirementsState }) => VNode;
export const InputRequirements: InputRequirementsComponent = ({ state }) => {
	const styles = useStyles();

	return (
		<ul className={ styles.inputRequirements }>
			{ Object.entries(state)
				.map(([ label, status ]) => (
					<li
						className={ cn(styles.requirement, status) }
						key={ label }
					>
						<span className={ styles.requirementIcon }>
							<InputRequirementStatusIcon status={ status }/>
						</span>
						<span className={ styles.requirementLabel }>
							&nbsp;{ label }
						</span>
					</li>
				))
			}
		</ul>
	);
};
