import makeStyles from '@material-ui/core/styles/makeStyles';
import cn from 'classnames';
import { VNode } from 'preact';
// eslint-disable-next-line import/no-unresolved
import placeholderImg from 'url:../img/placeholder.jpg';
import { ClassProp } from '../util';


const useStyles = makeStyles(() => ({
	featuredYacht: {
		display: 'flex',
		backgroundColor: 'gainsboro',
		backgroundImage: `url(${placeholderImg})`,
		backgroundPosition: 'center',
		backgroundSize: 'cover',
	},
}));

type FeaturedYachtComponent = (props: ClassProp) => VNode;
export const FeaturedYacht: FeaturedYachtComponent = ({ className }) => {
	const styles = useStyles();
	
	return (
		<div className={ cn(styles.featuredYacht, className) }/>
	);
};
