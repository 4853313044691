import MuiCheckBox, { CheckboxProps } from '@material-ui/core/Checkbox';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cn from 'classnames';
import { FunctionComponent } from 'preact';
import { useCallback, useState } from 'preact/hooks';


const useStyles = makeStyles(theme => ({
	checkbox: {
		'&:first-child > :first-child': {
			borderRadius: theme.spacing(1),
			border: '2px solid transparent',
		},
	},
	focus: {
		'&:first-child > :first-child': {
			border: `2px solid ${theme.palette.primary.main}`,
		},
	},
}));

export const Checkbox: FunctionComponent<CheckboxProps> = props => {
	const styles = useStyles();
	const [ focus, setFocus ] = useState(false);
	
	const handleFocus = useCallback(() => { setFocus(true);	}, []);
	const handleBlur  = useCallback(() => { setFocus(false);	}, []);
		
	return (
		<MuiCheckBox
			{ ...props }
			className={ cn(styles.checkbox, { [styles.focus]: focus }) }
			focusRipple
			onFocusCapture={ handleFocus }
			onBlurCapture={ handleBlur }
		/>
	);
};
