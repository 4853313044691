import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import { VNode } from 'preact';
import { useCallback, useEffect, useState } from 'preact/hooks';
import { Form, Checkbox, PasswordInputs, DisclaimerModal } from '../../components';


export type NewUserFormSubmitHandler = (password: string, acceptDisclaimer: boolean) => void;

type NewUserFormComponent = (props: {
	onSubmit: NewUserFormSubmitHandler;
	disabled: boolean;
}) => VNode;
export const NewUserForm: NewUserFormComponent = ({ onSubmit, disabled }) => {
	const [ password, setPassword ] = useState('');
	const [ passwordValid, setPasswordValid ] = useState(false);
	const [ acceptDisclaimer, setAcceptDisclaimer ] = useState(false);

	const handleSubmit = useCallback(() => {
		onSubmit(password, acceptDisclaimer);
	}, [ password, acceptDisclaimer ]);

	const [ disclaimerModalOpen, setDisclaimerModalOpen ] = useState(false);
	useEffect(() => {
		const handleHashChange = () => {
			setDisclaimerModalOpen(window.location.hash === '#tou');
		};
		handleHashChange();
		window.addEventListener('hashchange', handleHashChange);
		() => { window.removeEventListener('hashchange', handleHashChange); };
	}, []);
	
	return (
		<>
			<Typography component="p">
				Before continuing, you will need to set your password, and accept our
				Terms & Conditions.
			</Typography>
			<br/>
			<Form
				onSubmit={ handleSubmit }
				submitLabel="SET PASSWORD"
				disabled={ disabled || !passwordValid }
			>
				<PasswordInputs
					password={ password }
					setPassword={ setPassword }
					setValid={ setPasswordValid }
					autofocus
					disabled={ disabled }
				/>

				<FormControlLabel
					label={ (
						<span>I have read and accept the <a href="#tou">Terms&nbsp;&&nbsp;Conditions</a>.</span>
					) }
					control={ (
						<Checkbox
							checked={ acceptDisclaimer }
							onChange={ (ev, value) => setAcceptDisclaimer(value) }
							required
							disabled={ disabled }
						/>
					) }
				/>
			</Form>

			<DisclaimerModal
				open={ disclaimerModalOpen }
				onClose={ () => window.location.hash = '' }
				onAccept={ () => { setAcceptDisclaimer(true); } }
			/>
		</>
	);
};
