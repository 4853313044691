import { route } from 'preact-router';
import { useEffect } from 'preact/hooks';
import { api } from '../api';
import { routeWithCallbackUrl } from '../callback';
import { getTokensFromCookies } from '../cookies';
import { log, RouteProps } from '../util';


type LogoutRouteComponent = (props: RouteProps) => null;
export const LogoutRoute: LogoutRouteComponent = (): null => {
	useEffect(() => {
		const tokens = getTokensFromCookies();
		if(!tokens) {
			routeWithCallbackUrl('/');
			return;
		}


		{api.login.DELETE({ idToken: tokens.idToken, refreshToken: tokens.refreshToken })
			.then(response => {
				switch(response.status) {
					case 200:
						route(`/?callback=${encodeURIComponent(response.body.callback)}`);
						return;
					default:
						// eslint-disable-next-line no-alert
						alert('Error logging out');
						log.error('LOGOUT_UNEXPECTED_RESPONSE', response);
				}
				
			});}
	});
	
	return null;
};
