import TextField from '@material-ui/core/TextField';
import { VNode } from 'preact';
import { useEffect, useMemo, useState } from 'preact/hooks';
import { InputRequirements, InputRequirementsDef, useInputRequirements } from './input-requirements';


type PasswordInputsComponent = (props: {
	password: string;
	setPassword: (newPassword: string) => void;
	setValid: (valid: boolean) => void;
	disabled: boolean;
	autofocus?: boolean;
}) => VNode;
export const PasswordInputs: PasswordInputsComponent = ({ password, setPassword, setValid, disabled, autofocus }) => {
	const [ confirmPassword, setConfirmPassword ] = useState('');

	const passwordRequirements = useMemo<InputRequirementsDef>(() => ({
		'At least 8 characters long': value => value.length >= 8,
		'Includes at least one upper case letter': value => !!value.match(/[A-Z]/),
		'Includes at least one lower case letter': value => !!value.match(/[a-z]/),
		'Includes at least one number': value => !!value.match(/[0-9]/),
	}), []);

	const confirmRequirements = useMemo<InputRequirementsDef>(() => ({
		'Passwords must match': value => !!value.length && value === password,
	}), [ password ]);

	const {
		ref: passwordInputEl,
		state: passwordInputState,
		valid: passwordValid,
		error: passwordError,
	} = useInputRequirements(passwordRequirements);
	const {
		ref: confirmInputEl,
		state: confirmInputState,
		valid: confirmValid,
		error: confirmError,
	} = useInputRequirements(confirmRequirements);

	useEffect(() => {
		setValid(passwordValid && confirmValid);
	}, [ passwordValid, confirmValid ]);

	useEffect(() => {
		if(autofocus) {
			passwordInputEl.current?.focus();
		}
	}, []);

	return (
		<>
			<TextField
				inputRef={ passwordInputEl }
				type="password"
				variant="outlined"
				label="New Password"
				autoComplete="new-password"
				required
				value={ password }
				onChange={ ev => setPassword(ev.target.value) }
				disabled={ disabled }
				error={ passwordError }
			/>
			<InputRequirements state={ passwordInputState }/>

			<TextField
				inputRef={ confirmInputEl }
				type="password"
				variant="outlined"
				label="Confirm Password"
				autoComplete="new-password"
				required
				value={ confirmPassword }
				onChange={ ev => setConfirmPassword(ev.target.value) }
				disabled={ disabled }
				error={ confirmError }
			/>
			<InputRequirements state={ confirmInputState }/>
		</>
	);
};
