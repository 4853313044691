import { MuiThemeProvider }  from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { render } from 'preact';
import { Router} from 'preact-router';
import { LoginRoute, LogoutRoute, ResetRoute } from './routes';
import { theme } from './theme';
import { Page } from './components/page';


const Root = () => (
	<MuiThemeProvider theme={ theme }>
		<CssBaseline/>
		<Router>
			<LogoutRoute path="/logout"/>
			<Page default>
				<Router>
					<ResetRoute path="/reset"/>
					<LoginRoute default/>
				</Router>
			</Page>
		</Router>
	</MuiThemeProvider>
);

window.addEventListener('load', () => {
	const rootEl = document.getElementById('root');
	if(!rootEl) {
		throw new Error('Missing #root element.');
	}

	render(<Root/>, rootEl);
});
