import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';



const defaultTheme = responsiveFontSizes(createMuiTheme());

export const theme = responsiveFontSizes(createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			'@global': {
				html: {
					WebkitFontSmoothing: 'auto',
				},
				body: {
					backgroundColor: defaultTheme.palette.common.white,
				},
				a: {
					textDecoration: 'none',
					color: defaultTheme.palette.primary.main,
				},
			},
		},
		MuiBackdrop: {
			root: {
				backdropFilter: 'blur(5px)',
			},
		},
		MuiButton: {
			root: {
				textTransform: 'none',
			},
			contained: {
				boxShadow: defaultTheme.shadows[1],
				'&:hover': {
					boxShadow: defaultTheme.shadows[1],
				},
				'&:active': {
					boxShadow: defaultTheme.shadows[1],
				},
			},
			containedSizeLarge: {
				fontSize: defaultTheme.typography.h6.fontSize,
			},
		},
		MuiInput: {
			input: {
				backgroundColor: 'transparent !important',
			},
			underline: {
				'&:before': {
					borderBottomColor: defaultTheme.palette.grey[300],
				},
				'&:hover:not(.Mui-disabled):before': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&:hover:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&$focused:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
			},
		},
		MuiFilledInput: {
			root: {
				backgroundColor: defaultTheme.palette.grey[100],
				boxShadow:
					'inset 0px 2px 1px -1px rgba(0,0,0,0.05), inset 0px 1px 1px 0px rgba(0,0,0,0.1), inset 0px 1px 3px 0px rgba(0,0,0,0.1)',
				'&:hover': {
					backgroundColor: defaultTheme.palette.grey[100],
				},
				'&$focused': {
					backgroundColor: defaultTheme.palette.grey[200],
				},
			},
			underline: {
				'&:before': {
					borderBottomColor: defaultTheme.palette.grey[200],
				},
				'&:hover:before': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&:hover:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
				'&$focused:after': {
					borderBottomColor: defaultTheme.palette.primary.light,
				},
			},
		},
	},
	typography: {
		fontFamily: 'Roboto',
	},
	shape: {
		borderRadius: defaultTheme.spacing(1),
	},
	shadows: [
		'none',
		'0px 2px 1px -1px rgba(0,0,0,0.1),0px 1px 1px 0px rgba(0,0,0,0.07),0px 1px 3px 0px rgba(0,0,0,0.06)',
		'0px 3px 1px -2px rgba(0,0,0,0.1),0px 2px 2px 0px rgba(0,0,0,0.07),0px 1px 5px 0px rgba(0,0,0,0.06)',
		'0px 3px 3px -2px rgba(0,0,0,0.1),0px 3px 4px 0px rgba(0,0,0,0.07),0px 1px 8px 0px rgba(0,0,0,0.06)',
		'0px 2px 4px -1px rgba(0,0,0,0.1),0px 4px 5px 0px rgba(0,0,0,0.07),0px 1px 10px 0px rgba(0,0,0,0.06)',
		'0px 3px 5px -1px rgba(0,0,0,0.1),0px 5px 8px 0px rgba(0,0,0,0.07),0px 1px 14px 0px rgba(0,0,0,0.06)',
		'0px 3px 5px -1px rgba(0,0,0,0.1),0px 6px 10px 0px rgba(0,0,0,0.07),0px 1px 18px 0px rgba(0,0,0,0.06)',
		'0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.07),0px 2px 16px 1px rgba(0,0,0,0.06)',
		'0px 5px 5px -3px rgba(0,0,0,0.1),0px 8px 10px 1px rgba(0,0,0,0.07),0px 3px 14px 2px rgba(0,0,0,0.06)',
		'0px 5px 6px -3px rgba(0,0,0,0.1),0px 9px 12px 1px rgba(0,0,0,0.07),0px 3px 16px 2px rgba(0,0,0,0.06)',
		'0px 6px 6px -3px rgba(0,0,0,0.1),0px 10px 14px 1px rgba(0,0,0,0.07),0px 4px 18px 3px rgba(0,0,0,0.06)',
		'0px 6px 7px -4px rgba(0,0,0,0.1),0px 11px 15px 1px rgba(0,0,0,0.07),0px 4px 20px 3px rgba(0,0,0,0.06)',
		'0px 7px 8px -4px rgba(0,0,0,0.1),0px 12px 17px 2px rgba(0,0,0,0.07),0px 5px 22px 4px rgba(0,0,0,0.06)',
		'0px 7px 8px -4px rgba(0,0,0,0.1),0px 13px 19px 2px rgba(0,0,0,0.07),0px 5px 24px 4px rgba(0,0,0,0.06)',
		'0px 7px 9px -4px rgba(0,0,0,0.1),0px 14px 21px 2px rgba(0,0,0,0.07),0px 5px 26px 4px rgba(0,0,0,0.06)',
		'0px 8px 9px -5px rgba(0,0,0,0.1),0px 15px 22px 2px rgba(0,0,0,0.07),0px 6px 28px 5px rgba(0,0,0,0.06)',
		'0px 8px 10px -5px rgba(0,0,0,0.1),0px 16px 24px 2px rgba(0,0,0,0.07),0px 6px 30px 5px rgba(0,0,0,0.06)',
		'0px 8px 11px -5px rgba(0,0,0,0.1),0px 17px 26px 2px rgba(0,0,0,0.07),0px 6px 32px 5px rgba(0,0,0,0.06)',
		'0px 9px 11px -5px rgba(0,0,0,0.1),0px 18px 28px 2px rgba(0,0,0,0.07),0px 7px 34px 6px rgba(0,0,0,0.06)',
		'0px 9px 12px -6px rgba(0,0,0,0.1),0px 19px 29px 2px rgba(0,0,0,0.07),0px 7px 36px 6px rgba(0,0,0,0.06)',
		'0px 10px 13px -6px rgba(0,0,0,0.1),0px 20px 31px 3px rgba(0,0,0,0.07),0px 8px 38px 7px rgba(0,0,0,0.06)',
		'0px 10px 13px -6px rgba(0,0,0,0.1),0px 21px 33px 3px rgba(0,0,0,0.07),0px 8px 40px 7px rgba(0,0,0,0.06)',
		'0px 10px 14px -6px rgba(0,0,0,0.1),0px 22px 35px 3px rgba(0,0,0,0.07),0px 8px 42px 7px rgba(0,0,0,0.06)',
		'0px 11px 14px -7px rgba(0,0,0,0.1),0px 23px 36px 3px rgba(0,0,0,0.07),0px 9px 44px 8px rgba(0,0,0,0.06)',
		'0px 11px 15px -7px rgba(0,0,0,0.1),0px 24px 38px 3px rgba(0,0,0,0.07),0px 9px 46px 8px rgba(0,0,0,0.06)',
	],
	palette: {
		primary: {
			light: '#e566a9',
			main: '#d40072',
			dark: '#7f0043',
		},
		secondary: {
			light: '#66b7f0',
			main: '#0079d1',
			dark: '#00518a',
		},
		success: {
			main: '#4caf50',
			contrastText: '#ffffff',
		},
	},
}));
