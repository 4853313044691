import { VNode } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';
import { api } from '../../api';
import { Header, SuccessfulLoginAlert } from '../../components';
import { log, loginWaitAndRedirect, RouteProps } from '../../util';
import { ResetConfirmForm, ResetConfirmFormSubmitHandler } from './reset-confirm-form';
import { ResetErrors } from './reset-errors';
import { ResetInitForm } from './reset-init-form';


export type ResetStage = 'init' | 'confirm' | 'success';
export type ResetStatus = 'ready' | 'loading' | 'limit-exceeded' | 'invalid-code' | 'expired-code' | 'error';

type ResetRouteComponent = (props: RouteProps) => VNode;
export const ResetRoute: ResetRouteComponent = () => {
	const [ status, setStatus ] = useState<ResetStatus>('ready');

	const { isUpgrade, upgradeUsername } = useMemo(() => {
		const username = new URLSearchParams(window.location.search).get('upgrade');
		
		if(username) {
			api.resetPasswordInit.POST({ username })
				.then(response => {
					if(response.status !== 200) {
						setStatus('error');
					}
				});
		}
		
		return {
			isUpgrade: !!username,
			upgradeUsername: username,
		};
	},[]);
	
	const [ stage, setStage ] = useState<ResetStage>(isUpgrade ? 'confirm' : 'init');


	const [ username, setUsername ] = useState<string>(upgradeUsername ?? '');

	const handleInitSubmit = useCallback(() => {
		setStatus('loading');
		api.resetPasswordInit.POST({ username })
			.then(response => {
				setStatus('ready');

				switch(response.status) {
					case 200:
						setStage('confirm');
						return;
					case 400:
						log.error('BAD_REQUEST', response.body.errors);
						setStatus('error');
						return;
					case 429:
						setStatus('limit-exceeded');
						return;
					default:
						log.error('UNEXPECTED_RESPONSE', response);
						setStatus('error');
				}
			});
	}, [ username ]);

	const handleConfirmSubmit = useCallback<ResetConfirmFormSubmitHandler>((code, password) => {
		setStatus('loading');
		api.resetPasswordConfirm.POST({ username, password, code })
			.then(response => {
				setStatus('ready');

				switch(response.status) {
					case 200:
						setStage('success');
						loginWaitAndRedirect(username, password, false, () => setStatus('error'));
						break;
					case 400:
						if(response.body.errors.includes(api.resetPasswordConfirm.INVALID_CODE)) {
							setStatus('invalid-code');
							break;
						}
						if(response.body.errors.includes(api.resetPasswordConfirm.EXPIRED_CODE)) {
							setStatus('expired-code');
							break;
						}
					// eslint-disable-next-line no-fallthrough
					default:
						log.error('RESET_CONFIRM_UNEXPECTED_RESPONSE', response);
						setStatus('error');
						break;
				}
			});
	}, [ username ]);
	
	const gotoInit = useCallback(() => setStage('init'), []);

	useEffect(() => {
		if(isUpgrade) {
			const header = document.querySelector('header');
			header?.scrollIntoView();
		}
	}, []);
		
	return (
		<>
			<Header title="Reset Password"/>

			<ResetErrors status={ status } gotoInit={ gotoInit }/>

			{ stage === 'init' && (
				<ResetInitForm
					username={ username }
					setUsername={ setUsername }
					status={ status }
					onSubmit={ handleInitSubmit }
				/>
			)}

			{ stage === 'confirm' && (
				<ResetConfirmForm
					username={ username }
					status={ status }
					onSubmit={ handleConfirmSubmit }
					isUpgrade={ isUpgrade }
				/>
			)}

			{ stage === 'success' && status !== 'error' && (
				<SuccessfulLoginAlert/>
			)}
		</>
	);
};
