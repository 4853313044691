import Button from '@material-ui/core/Button';
import MuiModal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { FunctionComponent, VNode } from 'preact';
import { useCallback } from 'preact/hooks';
import { ClassProp } from '../util';


const Modal = MuiModal as FunctionComponent<ClassProp & { open: boolean, onClose: () => void }>;


const useStyles = makeStyles(theme => ({
	disclaimerModal: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},

	disclaimerModalContent: {
		display: 'grid',
		gridTemplateRows: 'auto 1fr auto',
		gap: theme.spacing(1),
		
		width: '100ch',
		maxWidth: '100vw',
		minHeight: '80vh',
		maxHeight: '80vh',
		padding: theme.spacing(2),
	},

	disclaimerText: {
		overflowY: 'scroll',
		display: 'flex',
		flexDirection: 'column',

		padding: theme.spacing(1),
		
		'& * + *': {
			marginTop: '1em',
		},
	},

	disclaimerActions: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));

export type DisclaimerModalComponent = (props: {
	onAccept: () => void;
	open: boolean;
	onClose: () => void;
}) => VNode;


export const DisclaimerModal: DisclaimerModalComponent = ({ onAccept, open, onClose }) => {
	const styles = useStyles();
	
	const handleAcceptClick = useCallback(() => {
		onAccept();
		onClose();
	}, [onAccept, onClose]);
	
	return (
		<Modal
			className={ styles.disclaimerModal }
			open={ open }
			onClose={ onClose }
		>
			<Paper className={ styles.disclaimerModalContent }>
				<Typography component="h2" variant="h3">
					Terms & Conditions
				</Typography>
				<article className={ styles.disclaimerText }>
					<TermsAndConditionsText/>
				</article>

				<div className={ styles.disclaimerActions }>
					<Button
						variant="contained"
						size="large"
						onClick={ onClose }
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						size="large"
						color="primary"
						onClick={ handleAcceptClick }
					>
						I accept these Terms & Conditions
					</Button>
				</div>
			</Paper>
		</Modal>
	);
};


const TermsAndConditionsText = () => (
	<>
		<Typography component="p">
			PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY, AS THEY CONTAIN IMPORTANT INFORMATION ABOUT YOUR RIGHTS AND OBLIGATIONS WHEN USING CHARTERINDEX.COM, CHARTERINDEXONLINE.COM AND CHARTERBROCHURE.COM
			(&quot;THE SITES&quot;).YOUR ATTENTION IS DRAWN IN PARTICULAR TO CLAUSE 8 (WARRANTIES), CLAUSE 9 (OUR LIABILITY), AND CLAUSE 10 (YOUR LIABILITY)
		</Typography>
		<Typography component="h2" variant="h6" id="1-introduction" lang="en">1. Introduction.</Typography>
		<Typography component="p">
			1 (i) The Sites are operated by Charter Index Limited (“Charter Index”, “we”, “us”, “our”). We are a limited company, registered in England and Wales, company number 4370794, and have our registered
			office at 8 Ravenscourt Place, Hammersmith, London, W6 0UN. You can also contact us here Contact Us. Our VAT number is G791 7538 86.
		</Typography>
		<Typography component="p">
			1 (ii) These Terms and Conditions are deemed to include our Privacy Policy and Notice and Takedown Policy, and are collectively known as &quot;these Terms&quot;. By using our Sites and any services we
			provide through our Sites (the “Services”), you consent to the processing of information about you in accordance with our Privacy Policy and you warrant that all data provided by you is accurate.
		</Typography>
		<Typography component="p">
			1 (iii) When you use the Sites or our Services, you agree to be bound by these Terms. If you do not agree to be bound by these Terms, please do not use the Sites or our Services.
		</Typography>
		<Typography component="p">
			1 (iv) Certain uses of the Sites, and the servers and systems on which our Sites are stored (our “Systems”) are prohibited and amount to a misuse of our Systems and the Sites. Please refer to clause 2.
		</Typography>
		<Typography component="p">
			1 (v) You may print and keep a copy of these Terms. They are a legal agreement between you and us. We may change or update these Terms at our discretion. Any changes we may make to these Terms in the
			future will be posted on the Sites and, where appropriate, notified to you by email. You are also expected to check this page from time to time to take notice of any changes we have made, as they are
			binding on you. Some of the provisions contained in these Terms of use may also be superseded by provisions or notices published elsewhere on our Sites. The then current version of these Terms will apply
			whenever you use the Sites.
		</Typography>
		<Typography component="p">
			1 (vi) Access to our Sites is permitted on a temporary basis, and we reserve the right to withdraw or amend the Services we provide on our Sites without notice (see below). We will not be liable if, for
			any reason, our Sites are unavailable at any time or for any period. From time to time, we may restrict access to some parts of our Sites, or our entire Sites, to users who have registered with us.
		</Typography>
		<Typography component="p">
			1 (vii) We aim to update our Sites regularly, and may change the content at any time. If the need arises, we may suspend access to our Sites, or close any of them indefinitely. Any of the material on our
			Sites may be out of date at any given time, and we are under no obligation to update such material.
		</Typography>
		<Typography component="h2" variant="h6" id="2-your-obligations-and-conduct" lang="en">2. Your Obligations and Conduct.</Typography>
		<Typography component="p">
			2 (i) You are responsible for making all arrangements necessary for you to have access to our Sites. You are also responsible for ensuring that all persons who access our Sites through your Internet
			connection are aware of these Terms, and that they comply with them. You accept that you are solely responsible for ensuring that your computer system meets all relevant technical specifications necessary
			to use the Sites and that your computer system is compatible with the Sites.
		</Typography>
		<Typography component="p">2 (ii) You must not misuse our Systems or the Sites. In particular, you must not:</Typography>
		<Typography component="p">
			(a) attempt to gain unauthorised access to, or hack into, circumvent security or otherwise disrupt the operation of our Systems and the Sites, or attempt to carry out any of the foregoing;
		</Typography>
		<Typography component="p">(b) attack our site via a denial-of-service attack or a distributed denial-of service attack;</Typography>
		<Typography component="p">
			(c) knowingly withhold information which may affect our ability to provide the Sites or the Services to you or others, or the security or integrity of the Services, the Systems or the Sites;
		</Typography>
		<Typography component="p">
			(d) misuse the Sites, Systems or Services by interfering with their normal operation, or attempting to access them using a method other than through the interfaces and instructions that we provide;
		</Typography>
		<Typography component="p">
			(e) attempt to reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form all or any part of the Sites, the System or the Services; or
		</Typography>
		<Typography component="p">
			(f) build any product or service using any part of the Sites or the Services which is similar to, or competes with, the Sites or the Services.
		</Typography>
		<Typography component="p">
			We will not be liable for any loss or damage caused by a distributed denial-of-service attack, viruses or other technologically harmful material that may infect your computer equipment, computer programs,
			data or other proprietary material due to your use of our Sites or to your downloading of any material posted on it, or on any website linked to it.
		</Typography>
		<Typography component="p">
			2 (iii) You must not use or attempt to use any automated program (including, without limitation, any spider or other web crawler) to access our Systems or the Sites, or to search, display or obtain links
			to any part of the Sites. Any such use or attempted use of an automated program (other than an automated program which is fully compliant with the Robots Exclusion Protocol (a &quot;Permitted
			Program&quot;)) shall be a misuse of our Systems and the Sites. Obtaining access to any part of our Systems or the Sites by means of any such automated programs (other than a Permitted Program) is
			strictly unauthorised.
		</Typography>
		<Typography component="p">
			2 (iv) You must not include in any other website any &quot;deep link&quot; to any page on the Sites without our prior written consent. Our Sites must not be framed on any other site, nor may you create a
			link to any part of our Sites other than the home page without our prior written consent.
		</Typography>
		<Typography component="p">
			2 (v) You must not upload or use inappropriate or offensive language or content or solicit any commercial services in any communication, form or email you send or submit, from or to the Site.
		</Typography>
		<Typography component="h2" variant="h6" id="3-registration" lang="en">3. Registration.</Typography>
		<Typography component="p">
			3 (i) Some Services offered on our Sites are provided free of charge, while others require payment. We reserve the right to change the cost of Services, or to charge for other Services, at any time.
			However, we will always notify you directly before any such change becomes effective. To receive details of yachts on www.charterindexonline.com, you must be a subscriber to that service (“Subscription”).
			Please Contact Us to request further information about the Subscriptions we offer and the terms and conditions which apply to such Subscriptions. At our discretion, we may refuse your Subscription
			application. If we accept your Subscription application, we will confirm this by sending you an email containing your account details.
		</Typography>
		<Typography component="p">
			3 (ii) Each Subscription is for a single user only unless otherwise applied for. You may not share your username and password with any other person or with multiple users on a network.
		</Typography>
		<Typography component="p">3 (iii) You undertake that all information provided by you for the purposes of subscribing with us is accurate and complete.</Typography>
		<Typography component="p">
			3 (iv) You accept sole responsibility for all use of and for keeping secret any account details and password that may have been given to you or chosen by you for use on the Sites. You will notify us
			immediately of any unauthorised use of them or any other breach of security of the Sites of which you become aware.
		</Typography>
		<Typography component="p">
			3 (v) By registering with us, you agree to accept responsibility for all activities that occur under your account or password, and agree you will not sell, transfer or assign your membership or any
			membership rights.
		</Typography>
		<Typography component="h2" variant="h6" id="4-intellectual-property" lang="en">4. Intellectual Property.</Typography>
		<Typography component="p">
			4 (i) You acknowledge that the copyright and all other intellectual property rights in the Sites (including, without limitation, all database rights, patent rights, trade marks, service marks, trading
			names, domain names, text, graphics, code, files and links) belong to us and/or our licensor(s). All such rights are reserved. Subject to clause 2, you may download material from the Sites for the sole
			purpose of using the Sites. However, you must not copy, transmit, modify, create derivative works from, distribute, republish, store (in whole or in part), frame, pass-off or link to any material or
			information on or downloaded from the Sites in any form or media without our prior written consent. Our status (and that of any identified contributors) as the authors of material on our Sites must always
			be acknowledged.
		</Typography>
		<Typography component="p">
			4 (ii) These Terms do not grant you any right to use our trade marks or other brand elements, so please do not use them without our express permission.
		</Typography>
		<Typography component="p">
			4 (iii) We respect the intellectual property rights of others. Charter Index operates on a &quot;notice and takedown&quot; basis and we do not monitor all content on our Sites. If you believe that your
			work has been copied, or dealt with in a way which constitutes infringement of your intellectual property rights, or are aware of any infringing material placed by any third party on the Website, please
			contact us by email at Contact Us, in the form specified in our Notice and Takedown Policy. Once this procedure has been followed and you have provided all requested information, Charter Index will make
			reasonable endeavours to remove infringing content within a reasonable time.
		</Typography>
		<Typography component="p">
			4 (iv) We reserve the right, but not the obligation, to terminate your licence to use any, or all, of the Sites or all or part of the Services if we determine in our sole and absolute discretion that you
			are involved in infringing activity, including alleged acts of first-time or repeat infringement, regardless of whether the material or activity is ultimately determined to be infringing.
		</Typography>
		<Typography component="h2" variant="h6" id="5-other-information-regarding-content-providerscontributors" lang="en">5. Other Information regarding Content Providers/Contributors.</Typography>
		<Typography component="p">
			5 (i) We are distributors of content supplied by other information content providers known as ‘Contributors’. We are not responsible for the statements, data, information or opinions expressed by those
			Contributors. Responsibility for the accuracy and completeness of such content lies solely with those Contributors and is not guaranteed by us. We are not the providers of such information and are
			therefore not liable for any delays, inaccuracies, errors or omissions in such content.
		</Typography>
		<Typography component="p">
			5 (ii) Given the volume of information posted by such Contributors, we cannot and do not monitor all of the information posted to the Sites. We are under no obligation to oversee, monitor or moderate any
			content from Contributors, and we expressly exclude our liability for any loss or damage arising from the use of any content by a Contributor in contravention of these Terms, whether the Sites are
			moderated or not. However, you acknowledge that, in order to ensure compliance with legal obligations, we may be required to review certain content submitted to our Sites to determine whether it is
			illegal or whether it violates these Terms (such as when unlawful content is reported to us). We may also modify, prevent access to, delete, or refuse to display content that we believe violates the law
			or these Terms, or otherwise remove information provided by Contributors at any time in our sole discretion.
		</Typography>
		<Typography component="p">
			5 (iii) Please use your best judgement when submitting information or content to the Sites. The Website reserves the right to delete, move or edit any submission or content at any time, for any reason or
			in its discretion, but has no obligation to review or remove any such submission or content.
		</Typography>
		<Typography component="p">
			5 (iv) By placing material on, or communicating with, the Sites, including for example by posting a comment to a post, you represent and warrant that you own or otherwise control all of the rights to the
			content that you provide, that the content is accurate, that it does not violate these Terms, and that it will not cause harm to any person or entity.
		</Typography>
		<Typography component="p">
			5 (v) You hereby grant Charter Index, its affiliates and related entities, including the Sites, a royalty-free, perpetual, irrevocable, non-exclusive right and license to use, copy, modify, display,
			archive, store, distribute, reproduce and create derivative works from all information you provide to us in any public area of the Site, in any form, media, software or technology of any kind now existing
			or developed in the future. Without limiting the generality of the previous sentence, you authorise Charter Index to include the information you provide in a searchable format that may be accessed by
			users of the Sites and other Charter Index websites. You also grant Charter Index and its affiliates and related entities the right to use your name and any other information about you that you provide in
			connection with its use and with the reproduction or distribution of such material, and also grant Charter Index the right to use any material, information, ideas, concepts, know-how or techniques
			contained in any communication you send to us for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products using such information. All rights in this
			paragraph are granted without the need for additional compensation of any sort to you.
		</Typography>
		<Typography component="p">
			5 (vi) Please note that Charter Index does not accept unsolicited materials or ideas for use or publication. Charter Index is not responsible for the similarity of any of its content or programming in any
			media to materials or ideas transmitted to Charter Index.
		</Typography>
		<Typography component="h2" variant="h6" id="6-posting-rules-for-contributors" lang="en">6. Posting Rules for Contributors.</Typography>
		<Typography component="p">
			6 (i) Interactive areas of the Site (&quot;News Feed&quot;) are intended to encourage debate. We expect people to differ - judgment and opinion are subjective things - and we encourage freedom of speech
			and a marketplace of ideas. But by using these areas of the Sites, you are participating in a community that is intended for all our users. Therefore, we reserve the right to remove any content posted on
			the Sites at any time for any reason. Without limiting our right to remove content for any reason (at our discretion), please do not post content or material that:
		</Typography>
		<ul class="jss33" lang="en">
			<li>
				contains vulgar, profane, abusive or hateful language, epithets or slurs, text or illustrations in poor taste, inflammatory attacks of a personal, racial or religious nature, or expressions of
				bigotry, racism, discrimination or hate;
			</li>
			<li>
				is defamatory, threatening, disparaging, grossly inflammatory, false, misleading, deceptive, fraudulent, inaccurate, unfair, contains gross exaggeration or unsubstantiated claims, violates the privacy
				rights of any third party, is unreasonably harmful or offensive to any individual or community, contains any actionable statement, or tends to mislead or reflect unfairly on any other person, business
				or entity;
			</li>
			<li>
				violates any right (including, without limitation, intellectual property rights) of Charter Index or any third party;
			</li>
			<li>
				<Typography component="p">
					discriminates on the grounds of race, religion, national origin, gender, age, marital status, sexual orientation or disability, or refers to such matters in any manner prohibited by law;
				</Typography>
			</li>
			<li>
				<Typography component="p">
					violates any local, state, federal, national or international law, rule, regulation or ordinance, or attempts to encourage such an evasion or violation;
				</Typography>
			</li>
			<li><Typography component="p">unfairly interferes with any third party&#x27;s uninterrupted use and enjoyment of the Sites;</Typography></li>
			<li>
				<Typography component="p">advertises, promotes or offers to trade any goods or services, except in areas specifically designated for such purpose;</Typography>
			</li>
			<li>
				<Typography component="p">
					uploads copyrighted or other proprietary material of any kind on the Website without the express permission of the owner of that material;
				</Typography>
			</li>
			<li>
				<Typography component="p">uses or attempts to use another&#x27;s account, password, service or Systems except as expressly permitted by these Terms;</Typography>
			</li>
			<li>
				<Typography component="p">
					includes images, photos, articles or other content that constitutes, promotes or encourages illegal acts, violation of any right of any individual or entity, violation of any local, state,
					federal, national or international law, rule, regulation or ordinance, guideline or regulation, or otherwise creates liability;
				</Typography>
			</li>
			<li>
				<Typography component="p">uploads or transmits viruses, trojans, worms, logic bombs or other harmful, disruptive or destructive files;</Typography>
			</li>
			<li>
				<Typography component="p">
					disrupts, interferes with, or otherwise harms or violates the security of the Website, or any Services, Systems resources, accounts, passwords, servers or networks connected to or accessible
					through the Website or affiliated or linked site;
				</Typography>
			</li>
			<li>
				<Typography component="p">
					&quot;inflames&quot; any individual or entity (e.g., sends repeated messages related to another user and/or makes derogatory or offensive comments about another individual), or repeats prior
					posting of the same message under multiple threads or subjects;
				</Typography>
			</li>
			<li><Typography component="p">is unrelated to the specific interactive area or the interactive area&#x27;s topic;</Typography></li>
			<li><Typography component="p">disguises the origin of the post; or</Typography></li>
			<li>
				<Typography component="p">collects or stores other users&#x27; personal data (as defined under the Data Protection Act 1998).</Typography>
			</li>
		</ul>
		<Typography component="p">
			A violation of certain of the foregoing rules in this clause 6 (“Posting Rule(s)”) may be referred to law enforcement authorities. Charter Index reserves the right (without notice) to: (i) disable and/or
			remove any material which violates the Posting Rules from the Sites without notice to you; and/or (ii) disclose any information as necessary to satisfy any law, regulation or governmental request.
			Decisions as to whether content violates any Posting Rule will be made by Charter Index in its sole discretion after we have actual notice of such posting.
		</Typography>
		<Typography component="h2" variant="h6" id="7-barring-from-the-site" lang="en">7. Barring from the Site.</Typography>
		<Typography component="p">
			We reserve the right to bar users from the Sites and/or restrict or block their access or use of any and all elements of our Services, on a permanent or temporary basis at our sole discretion. Any such
			user shall be notified and must not then attempt to use the Sites under any other name or through any other user.
		</Typography>
		<Typography component="h2" variant="h6" id="8-warranties" lang="en">8. Warranties.</Typography>
		<Typography component="p">
			8 (i) Whilst we endeavour to ensure that any material available for downloading from the Sites is not contaminated in any way, we do not warrant that such material will be free from infection, viruses
			and/or similar code.
		</Typography>
		<Typography component="p">
			8 (ii) Due to the nature of software and the Internet, we do not warrant that your access to, or the running of, the Sites will be uninterrupted or error free. We shall not be liable if we cannot process
			your details due to circumstances beyond our reasonable control.
		</Typography>
		<Typography component="p">
			8 (iii) The information provided on the Sites is for general interest only and does not constitute specific advice. Whilst we endeavour to ensure that the information on the Site is accurate, complete and
			up-to-date we make no warranties or representations that this is the case.
		</Typography>
		<Typography component="p">
			8 (iv) We make no warranty or guarantee that the Site or information available over it complies with laws other than those of England.
		</Typography>
		<Typography component="p">
			8 (v) Whilst we take every reasonable precaution and care in relation to our Sites, the material displayed on our Sites is provided without any guarantees, conditions or warranties as to its accuracy. To
			the extent permitted by law, we expressly exclude all conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity. This does not affect your
			statutory rights if you are dealing with us as a consumer.
		</Typography>
		<Typography component="p">
			8 (vi) The Sites contain links to websites operated by third parties. We have no control over their individual content. We therefore make no warranties or representations as to the accuracy or
			completeness of any of the information appearing in relation to any linked websites, and accept no responsibility for them or for any loss or damage that may arise from your use of them. The links are for
			your convenience only. We do not recommend any products or services advertised on those websites. If you decide to access any third party website linked from the Sites, you do so at your own risk.
		</Typography>
		<Typography component="p">
			8 (vii) The details of the yachts available on the Sites are for your information only. We cannot verify these details and therefore make no warranties or representations as to their accuracy or
			completeness, and these details, and any commentary on them, are not intended to amount to advice on which reliance should be placed. If you rely on these details, you do so at your own risk. We therefore
			disclaim all liability and responsibility arising from any reliance placed on the details of the yachts available on our Sites or by any visitor to our Sites, or by anyone who may be informed of any of
			its contents.
		</Typography>
		<Typography component="p">8 (viii) You warrant that:</Typography>
		<Typography component="p">
			(a) you own or have permissions to use all copyright or intellectual property rights in the content of any contributions or materials submitted by you to the News Feed or any other part of our Sites;
		</Typography>
		<Typography component="p">
			(b) the possession and use by Charter Index of such will not infringe the intellectual property rights of any person or contravene applicable law; and
		</Typography>
		<Typography component="p">(c) any content that is uploaded to, stored in or transmitted to the Sites by you will not be in contravention of the Posting Rules.</Typography>
		<Typography component="h2" variant="h6" id="9-our-liability" lang="en">9. Our Liability.</Typography>
		<Typography component="p">
			9 (i) Nothing in these Terms will be deemed to exclude our liability to you for death or personal injury arising from our negligence, or for fraudulent misrepresentation, or any other liability which
			cannot be excluded or limited under applicable law.
		</Typography>
		<Typography component="p">
			9 (ii) Subject to clause 9 (i), we will not be liable for any failures due to software or Internet errors or unavailability, or any other circumstances beyond our reasonable control.
		</Typography>
		<Typography component="p">
			9 (iii) Subject to clause 9 (i), we do not accept any liability for loss of your password or account details caused by a breakdown, error, loss of power or otherwise caused by or to your computer Systems.
		</Typography>
		<Typography component="p">
			9 (iv) Our Sites are provided on an “as-is” and “as available” basis and accordingly we make no warranties or representations that our Sites, or the materials hosted on them, are appropriate for whatever
			use you may wish to make of them.
		</Typography>
		<Typography component="p">
			9 (v) Where you access our Sites from outside of the United Kingdom, Charter Index makes no warranty, express or implied, that the Sites are permitted under any applicable laws or regulations that apply
			to such jurisdiction. You are therefore advised to satisfy yourself that you are lawfully able to subscribe to and use our Sites in the jurisdiction in which such use is taking place. Charter Index
			accepts no liability for your failure to do so.
		</Typography>
		<Typography component="p">
			9 (vi) We may put in place such Systems as we, from time to time, see fit to prevent automated programs being used to obtain unauthorised access to our Systems and the Sites. You are not permitted to use
			automated programs for such purposes and any such use or attempted use by you of such automated programs is at your own risk. Subject to clause 9 (i), we shall not be liable to you for any consequences
			arising out of, or in connection with, any such use or attempted use of automated programs to obtain unauthorised access to our Systems or the Sites.
		</Typography>
		<Typography component="p">
			9 (vii) Subject to clause 9 (i), we accept no liability for any loss suffered as a result of your use of the Sites or reliance on any information provided on it and exclude such liability to the fullest
			extent permitted by law.
		</Typography>
		<Typography component="p">9 (viii) Subject to clause 9 (i), we shall not be liable to you for:</Typography>
		<ul class="jss33" lang="en">
			<li>any indirect, consequential, special or punitive loss, damage, costs and expenses;</li>
			<li>loss of profit or contracts;</li>
			<li>loss of business;</li>
			<li>loss of reputation;</li>
			<li>depletion of goodwill; or</li>
			<li><Typography component="p">loss of, damage to, or corruption of, data, even if such loss was foreseeable.</Typography></li>
		</ul>
		<Typography component="p">
			9 (ix) To the extent permitted by applicable law, and subject to clause 9 (i), our total aggregate liability to you for all and any damages, losses or causes of action arising, by reason of, or in
			connection with, your use of our Sites, shall be limited to the lower of £500 or any fees paid by you to use our Services within the six months prior to the date of any claim for damages or losses
			arising.
		</Typography>
		<Typography component="p">
			When you use the &quot;contact&quot; or &quot;enquiry&quot; forms on the Sites to enquire about yachts and Services, your details (including your email address) will be sent by email directly to the yacht
			broker marketing the yachts or Services that you are enquiring about. We do not accept any liability for any subsequent communications that you receive directly from that yacht broker.
		</Typography>
		<Typography component="p">
			9 (x) Your dealings or communications through the Sites with any party other than Charter Index are solely between you and that third party. For example, certain areas of the Sites may allow you to
			conduct transactions or purchase goods or Services. In most cases, these transactions will be conducted by our third-party partners and vendors. Under no circumstances will Charter Index be liable for any
			goods, services, resources or content available through such third party dealings or communications, or for any harm related thereto. Please review carefully that third party&#x27;s policies and practices
			and make sure you are comfortable with those policies and practices before you engage in any transaction. Any complaints, concerns or questions you may have relating to materials provided by third parties
			should be forwarded directly to the third party.
		</Typography>
		<Typography component="h2" variant="h6" id="10-your-liability" lang="en">10. Your liability</Typography>
		<Typography component="p">
			You agree to indemnify and hold us and our associated companies, affiliates, officers, directors, agents and employees harmless from any liabilities, claims, losses or demands made by any third party
			arising out of your breach of these Terms (including documents incorporated by reference) or out of your violation of any law or the rights of any third party.
		</Typography>
		<Typography component="h2" variant="h6" id="11-termination" lang="en">11. Termination</Typography>
		<Typography component="p">
			11 (i) You acknowledge and agree that it is your responsibility to cancel your Subscription if you no longer wish to subscribe to our Services.
		</Typography>
		<Typography component="p">
			11 (ii) Charter Index may suspend or terminate your access to and use of our Sites, with or without cause, at any time, including if:
		</Typography>
		<Typography component="p">
			(a) Charter Index reasonably believes that there has been illegal or fraudulent use, misuse or abuse of features and functionalities of our Sites and/or the Services (in whole or in part);
		</Typography>
		<Typography component="p">(b) There is a technological failure on our Sites;</Typography>
		<Typography component="p">(c) We suspect that you have (or may have) breached any part of these Terms;</Typography>
		<Typography component="p">
			(d) We suspect you are acting in a manner that is detrimental to the conduct of our business or which may result in legal liability for you, us or third parties; or
		</Typography>
		<Typography component="p">(e) Charter Index reasonably believes that you have provided any false, inaccurate or misleading information.</Typography>
		<Typography component="p">
			11 (iii) On termination of these Terms (for whatever reason) all rights and licences granted to you pursuant to these Terms shall immediately come to an end.
		</Typography>
		<Typography component="p">
			11 (iv) The following clauses will survive termination of these Terms together with any other terms of these Terms that contemplate performance or obligations after termination: clause 8 (Warranties),
			clause 9 (Our Liability), clause 10 (Your Liability) and clause 12 (Other Important Terms).
		</Typography>
		<Typography component="h2" variant="h6" id="12-other-important-terms" lang="en">12. Other Important Terms</Typography>
		<Typography component="p">
			12 (i) These Terms, the subject matter of these Terms and their formation (and any non-contractual disputes or claims) are governed by English law. You irrevocably agree that the courts of England will
			have exclusive jurisdiction to settle any dispute or claim or their subject matter or formation (including non-contractual disputes or claims) which may arise out of, under, or in connection with, these
			Terms and for those purposes irrevocably submit all disputes to the exclusive jurisdiction of the English courts.
		</Typography>
		<Typography component="p">12 (ii) All notices shall be given:</Typography>
		<ul class="jss33" lang="en">
			<li>to us, by email Here or by post to Charter Index Limited, 8 Ravenscourt Place, Hammersmith, London, W6 0UN;</li>
			<li>to you, by email to the email address that you provide to us at the point of your registration, as may be amended by you from time to time.</li>
		</ul>
		<Typography component="p">
			12 (iii) All notices sent by email will be deemed to have been received on receipt (or, when received on a UK national holiday or on a Saturday or a Sunday, the next working day following the day of
			receipt) of a &#x27;read receipt&#x27;. All notice sent by post will be deemed to have been received 3 working days after the date of posting.
		</Typography>
		<Typography component="p">
			12 (iv) We operate a system to ensure that all customer feedback is dealt with fairly and consistently, and is properly recorded. We welcome any suggestions that you make about how we may improve our
			Services. Please contact us here. We aim to acknowledge all customer feedback.
		</Typography>
		<Typography component="p">
			12 (v) We may from to time to time change the content of the Sites or suspend or discontinue any aspect of the Sites, which may include your access to it. Subject to our notifying you to the contrary, any
			amendments or new content to the Sites will be subject to these Terms.
		</Typography>
		<Typography component="p">
			12 (vi) These Terms are the whole agreement between you and us. You acknowledge that you have not entered into this agreement in reliance on any statement, promise, warranty or representation made by us
			or on our behalf which is not set out in these Terms. Nothing in this clause shall limit or exclude any pre-contractual liability for fraud.
		</Typography>
		<Typography component="p">
			12 (vii) If a court or any other competent authority finds that any provision (or part of any provision) of the Agreement is invalid, illegal or unenforceable, that provision or part-provision will, to
			the extent required, be deemed deleted, and the validity and enforceability of the other provisions of these Terms will not be affected.
		</Typography>
		<Typography component="p">
			12 (viii) Sometimes our Sites may be impacted by events beyond our reasonable control, known as “Force Majeure” events. “Force Majeure” means anything outside the reasonable control of a party (other than
			in respect of payment obligations), including, but not limited to, acts of God, fire, storm, flood, earthquake, explosion, accident, acts of the public enemy, war, rebellion, sabotage, epidemic, labour
			dispute, power shortage, network failure, server crashes, deletion, corruption, loss or removal of data, including, without limitation, where a party ceases to be entitled to access the Internet or ceases
			to have access to the Internet, for whatever reason, any act or omission (including laws, regulations, disapprovals or failures to approve) of any government or government agency. If Charter Index is
			wholly or partially precluded from complying with its obligations under these Terms by Force Majeure, then Attest’s obligation to perform in accordance with these Terms will be suspended for the duration
			of the Force Majeure.
		</Typography>
		<Typography component="p">
			12 (ix) If we fail to insist that you perform any of your obligations under these Terms, or if we do not enforce our rights against you, or if we delay in doing so, that will not mean that we have waived
			our rights against you and will not mean that you do not have to comply with those obligations. If we do waive a default by you, we will only do so in writing, and that will not mean that we will
			automatically waive any later default by you.
		</Typography>
		<Typography component="p">
			12 (x) You may not assign or transfer any benefit, interest or obligation under these Terms. The provisions of the Contracts (Rights of Third Parties) Act 1999 shall not apply to these Terms.
		</Typography>
		<Typography component="p">
			12 (xi) You may not assign, transfer, charge, subcontract or deal in any other manner with all or any of your rights or obligations under these Terms, without our prior written consent.
		</Typography>
		<Typography component="p">
			12 (xii) We may at any time assign, transfer, charge, subcontract, or deal in any other manner with all or any of our rights under these Terms, and we may subcontract or delegate in any manner any or all
			of its obligations under these Terms to any third party or agent.
		</Typography>
		<Typography component="p">
			12 (xiii) Nothing in these Terms is intended to, or will be deemed to, constitute a partnership or joint venture of any kind between the parties, or constitute any party the agent of the other party for
			any purpose. No party will have authority to act as agent for, or to bind, the other party in any way.
		</Typography>
		<Typography component="p">
			12 (xiv) In order to ensure a smooth transition of the Services, in the event of a merger, acquisition, reorganization, sale of all or substantially all of our assets, or the sale of an individual website
			owned by us, we may transfer your personal data submitted in accordance with our Privacy Policy to a third party as a part of such merger, acquisition, reorganisation, or sale.
		</Typography>

	</>
);
