import { VNode } from 'preact';
import { useCallback, useMemo, useState } from 'preact/hooks';
import { api } from '../../api';
import { makeHrefWithCallbackUrl, routeWithCallbackUrl } from '../../callback';
import { Header, SuccessfulLoginAlert } from '../../components';
import { log, RouteProps } from '../../util';
import { loginWaitAndRedirect } from '../../util/login-wait-and-redirect';
import { LoginForm, LoginFormSubmitHandler } from './login-form';
import { LoginFormErrors } from './login-form-errors';
import { NewUserForm, NewUserFormSubmitHandler } from './new-user-form';


type NewUserInfo = api.login.PostResponse_NewUser['body'] & {
	username: string;
	oldPassword: string;
	remember: boolean;
};

type LoginStage = 'login' | 'new-user' | 'new-user-success';
export type LoginStatus = 'ready' | 'loading' | 'unauthorized' | 'error';

type LoginRouteComponent = (props: RouteProps) => VNode;
export const LoginRoute: LoginRouteComponent = () => {
	const [ stage, setStage ] = useState<LoginStage>('login');
	const [ status, setStatus ] = useState<LoginStatus>('ready');

	const [ newUserInfo, setNewUserInfo ] = useState<NewUserInfo | null>(null);

	const handleLoginSubmit = useCallback<LoginFormSubmitHandler>((username, password, remember) => {
		setStatus('loading');
		api.login.POST({ username, password, remember })
			.then(response => {
				setStatus('ready');
				switch(response.status) {
					case 200:
						window.location.href = response.body.callback;
						return;
					case 400:
						setStatus('error');
						log.error('BAD_REQUEST', response.body.errors);
						return;
					case 401:
						setStatus('unauthorized');
						return;
					case 403:
						switch(response.body.reason) {
							case 'password-reset-required':
								routeWithCallbackUrl('/reset', { upgrade: username });
								break;
							case 'temp-password':
								setStage('new-user');
								setNewUserInfo({
									...response.body,
									username,
									oldPassword: password,
									remember,
								});
								break;
							case 'show-disclaimer':
								// eslint-disable-next-line no-alert
								alert('Insert disclaimer here');
								break;
						}
						return;
					default:
						log.error('UNEXPECTED_RESPONSE', response);
						setStatus('error');
				}
			});
	}, []);

	const handleNewUserSubmit = useCallback<NewUserFormSubmitHandler>((newPassword, acceptDisclaimer) => {
		if(!newUserInfo) {
			throw new Error('Missing newUserInfo!');
		}
		
		setStatus('loading');
		api.loginNewUser.POST({
			accessToken: newUserInfo.accessToken,
			username: newUserInfo.username,
			oldPassword: newUserInfo.oldPassword,
			newPassword,
			acceptDisclaimer,
		})
			.then(response => {
				switch(response.status) {
					case 200:
						setStage('new-user-success');
						loginWaitAndRedirect(newUserInfo.username, newPassword, newUserInfo.remember, () => setStatus('error'));
						break;
					default:
						log.error('LOGIN_NEW_USER_FAILED', response);
						setStatus('error');
				}
			});
	}, [newUserInfo]);

	const forgotPasswordHref = useMemo(() => makeHrefWithCallbackUrl('/reset'), []);
	
	const title = useMemo<string>(() => {
		switch(stage) {
			case 'login':
				return 'Professional Sign In';
			case 'new-user':
			case 'new-user-success':
				return 'Welcome to Charter Index';
		}
	}, [stage]);
	
	return (
		<>
			<Header title={ title }/>
			<LoginFormErrors status={ status }/>

			{ stage === 'new-user-success' && status !== 'error' && (
				<SuccessfulLoginAlert/>
			)}

			{ stage === 'login' && (
				<>
					<LoginForm
						onSubmit={ handleLoginSubmit }
						status={ status }
					/>
					<br/>
					<a href={ forgotPasswordHref }>Forgot your password?</a>
				</>
			)}

			{ stage === 'new-user' && (
				<NewUserForm
					onSubmit={ handleNewUserSubmit }
					disabled={ status === 'loading' }
				/>
			)}
		</>
	);
};
