import Typography from '@material-ui/core/Typography';
import { VNode } from 'preact';
import { useCallback } from 'preact/hooks';
import { Alert, UnknownErrorAlert } from '../../components';
import { ResetStatus } from './reset-route';


const ContactSupport = () => (
	<Typography component="p">
		If you are having trouble accessing your account, you can contact our support.
	</Typography>
);

type ResetErrorsComponent = (props: {
	status: ResetStatus;
	gotoInit: () => void;
}) => VNode | null;
export const ResetErrors: ResetErrorsComponent = ({ status, gotoInit }) => {
	const handleGotoInit = useCallback((ev: Event) => {
		ev.preventDefault();
		gotoInit();
	}, [ gotoInit ]);
	
	switch(status) {
		case 'error':
			return <UnknownErrorAlert action="while resetting your password"/>;

		case 'invalid-code':
			return (
				<Alert
					severity="error"
					title="That verification code does not appear to be correct."
					content={ (
						<>
							<Typography component="p">
								Please check that the code you entered matches the code which we
								emailed to you, and try again.
							</Typography>
							<ContactSupport/>
						</>
					) }
				/>
			);

		case 'expired-code':
			return (
				<Alert
					severity="error"
					title="That verification code has expired."
					content={ (
						<>
							<Typography component="p">
								<a href="#" onClick={ handleGotoInit }>
									Click here
								</a>&nbsp; to request a new one.
							</Typography>
							<ContactSupport/>
						</>
					) }
				/>
			);
		case 'limit-exceeded':
			return (
				<Alert
					severity="error"
					title="Too many requests"
					content={ (
						<>
							<Typography component="p">
								There have been several attempts made to reset your password
								in the last hour. For	security reasons, we are temporarily
								unable to accept any more.
								Please wait a short time and try again.
							</Typography>
							<ContactSupport/>
						</>
					) }
				/>
			);
		default:
			return null;
	}
};
