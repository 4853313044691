import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { VNode } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { Form } from '../../components/form';
import { ResetStatus } from './reset-route';


type ResetInitFormComponent = (props: {
	status: ResetStatus;
	username: string;
	setUsername: (newUsername: string) => void;
	onSubmit: () => void;
}) => VNode | null;
export const ResetInitForm: ResetInitFormComponent = ({ status, username, setUsername, onSubmit }) => {
	const usernameInputEl = useRef<HTMLInputElement>();
	
	useEffect(() => {
		setTimeout(() => {
			usernameInputEl.current?.focus();
		});
	}, []);
	

	const disabled = status === 'loading';

	return (
		<>
			<Typography component="p">
				Enter your email address, and we will send you an email with a verification code.
			</Typography>
			<Typography component="p">
				Once you have received your code, you will be able to use it to reset your password.
			</Typography>
			
			<br/><br/>

			<Form
				submitLabel="REQUEST PASSWORD RESET"
				onSubmit={ onSubmit }
				disabled={ disabled }
			>
				<TextField
					inputRef={ usernameInputEl }
					type="email"
					variant="outlined"
					label="Email Address"
					required
					value={ username }
					onChange={ ev => setUsername(ev.target.value) }
					autoFocus
					disabled={ disabled }
				/>
			</Form>
		</>
	);
};
